.terms {
  padding: 160px 48px;
  p {
    margin-bottom: 16px;
  }
  .center {
    text-align: center;
  }
  u {
    text-decoration: underline;
  }
  b {
    font-weight: 700;
  }
  a {
    text-decoration: underline;
  }
  ol {
    list-style: auto;
    margin-left: 20px;
    margin-bottom: 16px;
  }
  ul {
    list-style: disc;
    margin-left: 20px;
    margin-bottom: 16px;
  }
  li {
    margin-bottom: 16px;
  }
  &__title {
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    margin-bottom: 24px;
  }
  &__subtitle {
    text-decoration: underline;
  }
  &__sectiontitle {
    font-size: 20px;
    font-weight: 700;
  }
}