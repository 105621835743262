@import "variables.scss";
@import "reset.scss";
@import "fonts.scss";

$grayBg: #333333;
$grayText: #adadad;
$graySubtext: #7f7f7f;

.avatar {
  padding: 160px 80px;
  display: flex;

  @media (max-width: $mobileWidth) {
    flex-direction: column;
    padding: 100px 20px;
  }

  &__title {
    font: 900 40px $fontXWide;
    text-transform: uppercase;
    margin-left: -300px;

    @media (max-width: $mobileWidth) {
      margin: 0 0 50px;
    }
  }

  &__description {
    font: 400 22px $fontXThin;
    margin: 50px 0;
  }

  &__thumbs {
    flex: 0 0 auto;

    @media (max-width: $mobileWidth) {
      order: 1;
      display: flex;
    }
  }

  &__thumbs-container {
    max-height: calc(100vh - 350px);
    overflow-y: scroll;
    overflow-x: hidden;

    @media (max-width: $mobileWidth) {
      display: flex;
    }
  }

  &__thumb-item {
    width: 100px;
    height: 100px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border: 2px solid rgba(0,0,0,0);
    transform: scale(1);
    transition: transform 200ms;

    &--selected {
      border: 2px solid #FEB73C;
      border-radius: 999px;
      transform: scale(1.5);
    }

    margin: 40px;

    @media (max-width: $mobileWidth) {
      flex: 0 0 auto;
      width: 100px;
      height: 100px;
    }

  }

  &__thumbs-arrow {
    text-align: center;
    margin: 15px;
    @media (max-width: $mobileWidth) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img {
      width: 40px;
      height: 40px;
    }
  }

  &__canvas {
    flex: 0 0 auto;
    width: 50vw;

    @media (max-width: $mobileWidth) {
      margin: 0 auto;
      width: 100%;
      max-width: 400px;
    }
  }

  &__canvas-item {
    width: 100%;
  }

  &__settings {
    padding-left: 100px;
    z-index: 1;
    @media (max-width: $mobileWidth) {
      padding-left: 0;
      order: 2;
    }
  }
}

.avatar-colors {
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #fff;

  &:last-child {
    border-bottom: 0;
  }

  &__header {
    font-size: 20px;
    font-weight: 700;
    color: #FEB73C;
    padding: 10px 0;
    text-transform: uppercase;
    line-height: 25px;

    img {
      vertical-align: -10%;
      margin-right: 16px;
    }
  }

  &__palette {
    display: none;
    flex-wrap: wrap;
    width: 162px;
    margin-top: 20px;
  }

  &__square {
    flex: 0 0 auto;
    width: 20px;
    height: 20px;
    border: 1px solid rgba(0,0,0,0);
    margin-right: 7px;
    margin-bottom: 7px;
    border-radius: 3px;
  }

  &__minus {
    display: none;
  }

  &--expanded &__palette {
    display: flex;
  }

  &--expanded &__minus {
    display: inline;
  }

  &--expanded &__plus {
    display: none;
  }

}