@import 'variables.scss';

.glenn-tommie {
	display: block;
	width: 100%;
	padding: 160px 0;
}

.glenn-tommie__container {
	display: flex;
	flex-direction: row;
	align-items: flex-start;

	width: 100%;
	max-width: 1232px;
	margin: 0 auto;
	padding: 0 16px;

	@media (max-width: $mobileWidth) {
		flex-direction: column;
	}
}

.glenn-tommie__cover {
	width: 100%;
	margin: 48px auto;

	.glenn-tommie__cover__image {
		width: 100%;
	}
}

.glenn-tommie__title {
	max-width: 720px;
	font-size: 48px;
	font-family: $fontXWide;
	line-height: 56px;
	font-weight: 600;
	text-transform: uppercase;

	@media (max-width: $mobileWidth) {
		max-width: 100%;
		font-size: 40px;
		line-height: 48px;
	}

	@media (max-width: 480px) {
		font-size: 28px;
		line-height: 36px;
	}

	@media (max-width: 360px) {
		font-size: 22px;
		line-height: 30px;
	}
}

.glenn-tommie__text {
	margin: 0 0 24px 0;
	font-size: 18px;
	line-height: 28px;
	color: #ffffff;
	opacity: 0.8;
	text-align: justify;

	@media (max-width: $mobileWidth) {
		font-size: 16px;
		line-height: 24px;
	}
}

.glenn-tommie__container__column {
	flex: 1;

	&:nth-of-type(1) {
		padding: 0 32px 0 0;
	}

	&:nth-of-type(2) {
		padding: 0 0 0 32px;
	}

	@media (max-width: $mobileWidth) {
		&:nth-of-type(1),
		&:nth-of-type(2) {
			margin: 0;
			padding: 0;
		}
	}
}

.glenn-tommie__individuals {
	margin: 176px auto 0 auto;

	@media (max-width: $mobileWidth) {
		margin-top: 96px;
	}
}

.glenn-tommie__person {
	flex: 1;

	&:nth-of-type(1) {
		padding: 0 32px 0 0;
	}

	&:nth-of-type(2) {
		padding: 0 0 0 32px;
	}

	@media (max-width: $mobileWidth) {
		flex: 0 auto;

		&:nth-of-type(1),
		&:nth-of-type(2) {
			margin: 0 0 48px 0;
			padding: 0;
		}
	}
}

.glenn-tommie__person__header {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	margin: 0 0 72px 0;

	@media (max-width: $mobileWidth) {
		flex-direction: column-reverse;
		justify-content: flex-start;
		margin: 0 0 24px 0;
	}
}

.glenn-tommie__person__name {
	position: absolute;
	bottom: -48px;
	left: 0;

	font-family: $fontXWide;
	font-size: 56px;
	line-height: 64px;
	font-weight: 600;
	text-transform: uppercase;

	@media (max-width: $mobileWidth) {
		position: relative;
		bottom: auto;
		margin: 24px 0 0 0;

		font-size: 40px;
		line-height: 48px;
	}
}

.glenn-tommie__person__header--inverse {
	justify-content: flex-start;

	.glenn-tommie__person__name {
		left: auto;
		right: 0;
	}
}

.glenn-tommie__person__picture {
	width: 100%;
	max-width: 322px;

	@media (max-width: $mobileWidth) {
		max-width: 256px;
	}

	@media (max-width: 640px) {
		max-width: 200px;
	}

	@media (max-width: 480px) {
		max-width: 176px;
	}
}
