@import '../variables';

.splash-pass {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;

  @media (max-width: $mobileWidth) {
    display: block;
    padding-bottom: 20vh;
  }

  &__content {
    @media (min-width: $mobileWidth) {
      width: 40vw;
    }
  }

  &__title {
    font: 900 50px $fontXWide;
    text-transform: uppercase;

    @media (max-width: $mobileWidth) {
      font-size: $titleSizeMobile;
      padding: 0 30px;
    }
  }

  &__summary {
    margin-top: 30px;

    p {
      width: 70%;
      margin: 20px 30px;

      @media (max-width: $mobileWidth) {
        width: auto;
      }
    }
  }

  &__baton-loop {
    width: 90%;
    @media (max-width: $mobileWidth) {
      width: 100%;
    }
  }

  &__images {
    position: relative;
    width: 25vw;
    height: 25vw;

    @media (max-width: $mobileWidth) {
      width: auto;
      height: auto;
      margin: 20px 30px;
    }
  }

  &__image-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 75%;
    @media (max-width: $mobileWidth) {
      position: static;
      width: 100%;
    }
  }

  &__image-bottom {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 60%;

    @media (max-width: $mobileWidth) {
      width: 65%;
      bottom: -30%;
    }
  }

  &__batons {
    position: absolute;
    width: 65vw;
    right: 0;
    bottom: -150px;
    text-align: left;

    img {
      height: 5.5vw;
      margin: 1vw;
      opacity: 0;
    }

    @media (max-width: $mobileWidth) {
      width: 100%;
      bottom: -200px;

      img {
        height: 100px;
      }
    }
  }
}