@import "../variables.scss";
@import "../reset.scss";
@import "../fonts.scss";

$grayBg: #333333;
$grayText: #adadad;
$graySubtext: #7f7f7f;

.gallery {
  padding: 100px 120px;

  @media (max-width: $phoneWidth) {
    padding: 100px 20px;
  }

  &__total-raised {
    float: right;

    @media (max-width: $phoneWidth) {
      float: none;
    }
  }

  &__title {
    font: 900 48px $fontXWide;
    margin: 20px 0;
    text-transform: uppercase;
  }

  &__subtitle {
    font: 700 18px $fontXThin;
    margin: 20px 0 0;
    color: rgba(255,255,255,0.66);
    text-transform: uppercase;
  }

  &__section-title {
    font: 700 20px $fontXThin;
  }

  &__filters {
    @media (max-width: $phoneWidth) {
      margin-bottom: 20px;
    }
  }

  &__header {
    margin: 30px 0 30px;
    border-bottom: 1px solid rgba(255,255,255,0.25);

    @media (max-width: $mobileWidth) {
      display: none;
    }
  }

  &__header-evolutions {
    display: flex;
    justify-content: flex-start;

    > span {
      flex: 0 0 auto;

      &:nth-child(1) {
        width: 20%;
      }

      &:nth-child(2) {
        width: 33%;
      }

      &:nth-child(3) {
        width: 41%;
      }

      > span {
        display: inline-block;
        transform: translateX(50%);
      }
    }

    @media (max-width: 1400px) {
      display: none;
    }
  }

  &__section-title {
    margin: 20px 0;
    font: 900 30px/50px $fontXThin;
    text-transform: uppercase;
  }

  &__list {
    flex: 1 0 auto;
  }

  &__load-more {
    text-align: center;
    margin: 32px 0;
  }
}