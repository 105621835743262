@import 'variables.scss';
@import 'reset.scss';
@import 'fonts.scss';

$grayBg: #333333;
$grayText: #adadad;
$graySubtext: #7f7f7f;

.mint {
  > .social-button__inverted {
    margin-right: 8px;
  }

  padding: 132px 30px;

  &__row {
    display: flex;
  }

  &__col-left {
    flex: 0 0 auto;
    width: 70vw;
  }

  &__title {
    font: 900 56px $fontXWide;
    margin: 20px 0;
    text-transform: uppercase;
  }

  &__subtitle {
    font-size: 16px;
    margin-bottom: 32px;
  }

  &__section-title {
    margin-top: 20px;
    font: 900 30px/50px $fontXThin;
    text-transform: uppercase;
  }

  &__section-description {
    font-size: 18px;
    margin-bottom: 20px;
  }

  &__team-selector {
    display: flex;
    margin: -10px;
  }

  &__team-option {
    flex: 0 0 25%;
    overflow: hidden;
    &.disabled {
      opacity: 0.3;
      cursor: default;
    }
  }

  &__team-inner {
    background-color: $grayBg;
    border-radius: 10px;
    padding: 15px 5px 15px 15px;
    cursor: pointer;
    position: relative;
    margin: 10px;
  }

  &__option-radio,
  &__option-radio--selected {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 25px;
    height: 25px;
    border: 2px solid #fff;
    border-radius: 25px;
  }

  &__option-radio--selected {
    background-color: #fff;
  }

  &__team-name {
    font: 700 24px $fontXThin;
    color: #fff;
    text-transform: uppercase;
  }

  &__remaining-count {
    font: 400 18px $fontXThin;
    margin-bottom: 16px;
    .mintable {
      display: inline-block;
      margin-right: 8px;
      color: #36f995;
    }
  }

  &__progress-bar {
    opacity: 1;
    animation: pulse 2s infinite;
    box-shadow: 0 0 8px 0 rgba(255, 255, 255, 0.33);
  }

  &__member-name {
    white-space: nowrap;
    font: 700 18px $fontXThin;
    text-transform: uppercase;
    color: $grayText;
    overflow: hidden;
    position: relative;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 20px;
      height: 100%;
      background: linear-gradient(
        90deg,
        rgba(51, 51, 51, 0) 0%,
        rgba(51, 51, 51, 1) 100%
      );
    }
  }

  &__member-organization {
    font-weight: 400;
    margin-left: 5px;
    color: $graySubtext;
  }

  &__quantity-input {
    display: flex;
    line-height: 30px;
    text-align: center;
  }

  &__quantity-less,
  &__quantity-more {
    font: 400 34px $fontXWide;
    width: 30px;
    line-height: 22px;
    cursor: pointer;
  }
  &__quantity-more {
    line-height: 26px;
  }

  &__quantity-current {
    width: 40px;
    font: 400 18px $fontXThin;
    line-height: 30px;
    height: 30px;
    border-radius: 100px;
    background-color: gray;
  }

  &__quantity-field {
    padding: 0;
    text-align: center;
    width: 40px;
    line-height: 30px;
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }

  &__link {
    font: 700 14px $fontXThin;
    text-decoration: underline;
    text-transform: uppercase;
    opacity: 0.8;
    &.large {
      font-size: 18px;
      text-decoration: none;
      margin-bottom: 24px;
      opacity: 1;
    }
  }

  &__error {
    margin: 16px 0;
    color: #f00;
    a {
      text-decoration: underline;
    }
  }
}

.mint.not-greenlisted {
  text-align: center;
  .mint__title {
    text-transform: initial;
    font: 400 18px $font;
    max-width: 800px;
    margin: 0 auto;
    a {
      text-decoration: underline;
    }
  }
}

.mint.confirmation {
  .mint__baton-video {
    width: 400px;
    height: 400px;
    margin: 0 auto;
    video {
      width: 100%;
    }
  }
  .ptb__button {
    margin-bottom: 90px;
  }
}

.mint-black {
  padding: 132px 30px;

  &__header {
    text-align: center;
  }

  &__title {
    font: 900 56px $fontXWide;
    margin: 20px 0;
    text-transform: uppercase;
  }

  &__subtitle {
    font: 900 24px $fontXWide;
    text-transform: uppercase;
    text-align: center;
  }

  &__description {
    font-size: 18px;
    opacity: 0.8;
  }

  &__teams {
    display: flex;
    margin: 36px -20px;
  }

  &__team {
    flex: 0 0 25%;
  }

  &__gold-baton {
    &-inner {
      margin: 20px;
      text-align: center;
    }

    &-image {
      &.empty {
        padding: 20px;
      }
    }
  }

  &__team-name {
    font: 700 24px $fontXThin;
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  &__border {
    border: 2px solid rgba(255, 255, 255, 0.33);
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    height: 50px;
    border-top: none;
    position: relative;
    margin-bottom: 56px;
    &:after {
      content: '';
      background: rgba(255, 255, 255, 0.33);
      width: 2px;
      height: 50px;
      display: block;
      position: absolute;
      bottom: -52px;
      left: 50%;
    }
  }

  &__button-container {
    text-align: center;
    &-inner {
      margin: 32px auto;
      padding: 68px 105px;
      display: inline-block;
      border: 1px solid rgba(255, 255, 255, 0.33);
    }
  }

  &.confirmation {
    text-align: center;
    .mint-black {
      &__baton-image {
        margin-bottom: 32px;
        img {
          width: 480px;
          margin: 0 auto;
        }
      }
      &__title {
        font-size: 24px;
        margin-bottom: 16px;
      }
      &__subtitle {
        font-size: 16px;
        opacity: 0.66;
        margin-bottom: 32px;
      }
    }
    .ptb__button {
      margin-bottom: 64px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .mint {
    &__col-right {
      text-align: center;
      img {
        max-width: 120px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .mint {
    &__row {
      flex-direction: column-reverse;
    }
    &__col-left {
      flex: 0 0 100%;
      width: inherit;
    }
  }
}

@media screen and (max-width: 1024px) {
  .mint-black {
    &__title {
      font-size: 28px;
      text-align: left;
    }

    &__description {
      text-align: left;
    }

    &__teams {
      flex-wrap: wrap;
    }

    &__team {
      flex: 0 0 50%;
    }

    &__button-container-inner {
      padding: 68px 72px;
    }
  }

}

@media screen and (max-width: 768px) {
  .mint {
    &__team-selector {
      flex-wrap: wrap;
    }
    &__team-option {
      flex: 0 0 50%;
    }
  }

  .mint.confirmation {
    .mint__title {
      font-size: 40px;
    }
  }
}

@media screen and (max-width: 500px) {
  .mint {
    &__team-option {
      flex: 0 0 100%;
    }
  }

  .mint-black.confirmation {
    .mint-black__title {
      text-align: center;
    }
    .mint-black__baton-image {
      img {
        width: 80%;
      }
    }
  }
}

@keyframes pulse {
      0% {
        opacity: 1;
      }

      70% {
        opacity: 0.66;
      }

      100% {
        opacity: 1;
      }
    }