@import "variables.scss";
@import "reset.scss";
@import "fonts.scss";

$grayBg: #333333;
$grayText: #adadad;
$graySubtext: #7f7f7f;

.account {
  padding: 160px 128px;

  &.not-connected {
    padding-top: 160px;
    text-align: center;
  }

  &.not-connected &__title {
    font-size: 40px;
    margin-bottom: 40px;
  }

  &.no-batons {
    text-align: center;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .account__title {
      font-size: 40px;
      margin-bottom: 40px;
    }
    .account__content {
      flex: 1 1;
    }
    .ptb__button {
      margin-right: 16px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__header {
    position: relative;
  }

  &__claim-btn {
    @media screen and (min-width: $phoneWidth) {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &__footer-text {
    opacity: 0.66;
    max-width: 510px;
    margin: 200px auto 0;
  }

  &__row {
    display: flex;
  }

  &__title {
    font: 900 48px $fontXWide;
    margin: 20px 0;
    text-transform: uppercase;
  }
  
  &__subtitle {
    font: 900 28px $fontXWide;
    margin: 24px 0;
    text-transform: uppercase;
  }

  &__description {
    font-size: 18px;
  }

  &__container {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 32px -24px;
  }

  &__baton {
    flex: 0 0 33.3%;
    cursor: pointer;
    display: flex;
    
    &-inner {
      padding: 60px 36px 96px;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      flex: 1;
      position: relative;
    }

    &-summary, &-image {
      flex: 0 0 50%;
    }

    &-image {
      margin-top: -24px;
    }
  }

  &__baton-name {
    font: 700 20px/30px $fontXThin;
    text-transform: uppercase;
  }

  &__baton-description {
    font: 400 18px/24px $fontXThin;
    text-transform: uppercase;
    opacity: 0.8;
  }

  &__baton-progress {
    display: inline-block;
    width: 100%;
    margin-top: 10px;
  }

  &__baton-donate {
    text-align: center;
    width: 100%;
    .ptb__button {
      font-size: 16px;
      font-weight: 900;
      padding: 6px 12px;
      line-height: initial;
      min-width: initial;
      margin-top: 20px;
    }
  }

  &__load-more {
    text-align: center;
    margin: 32px 0;
  }

  &__footer {
    text-align: center;
    margin-top: 180px;

    .link {
      display: block;
      margin-top: 24px;
      margin-bottom: 176px;
      font: 900 18px $fontXThin;
      text-decoration: underline;
      text-transform: uppercase;
    }
  }
}

@media screen and (max-width: 1280px) {
  .account {
    padding: 160px 32px;

    &__container {
      margin: 32px 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .account {
    &__header {
      text-align: center;
    }
    &__title {
      font-size: 36px;
    }
    &__baton {
      flex: 0 0 50%;
    }
    &__footer {
      margin-top: 40px;
    }
  }
}

@media screen and (max-width: 580px) {
  .account {
    &__baton {
      flex: 0 0 100%;
    }
  }
}