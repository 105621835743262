@import 'variables.scss';

.fwb {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100vw;
	min-height: 100vh;
	margin: 0;
	padding: 16px;

	text-align: center;

	.fwb__content__description {
		margin: 24px auto 40px auto;

		font-family: $fontXWide;
		font-weight: 400;
		font-size: 32px;
		line-height: 32px;
		text-transform: uppercase;
	}

	.fwb__form__input {
		max-width: 480px;
		width: 100%;
		height: 48px;
		margin: 0 auto 16px auto;

		background: rgba(255, 255, 255, 0.25);
		color: #ffffff;
		border-radius: 48px;
		
		font-family: $fontXThin;
		font-weight: 700;
		font-size: 18px;

		text-transform: uppercase;
	}

	.fwb__form__button {
		max-width: 480px;
		width: 100%;
		height: 48px;
		margin: 16px auto 0 auto;

		background: rgba(255, 255, 255, 1);
		color: #000000;
		border-radius: 48px;
		
		font-family: $fontXThin;
		font-weight: 900;
		font-size: 18px;

		text-transform: uppercase;
		cursor: pointer;
	}

	.fwb__response {
		max-width: 480px;
		margin: 24px 0 0 0;
		font-size: 16px;
		line-height: 24px;

		a {
			border-bottom: 1px solid #fff;
		}
	}
}

.fxx {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100vw;
	min-height: 100vh;
	margin: 0;
	padding: 16px;

	text-align: center;

	.fxx__content__description {
		margin: 24px auto 40px auto;

		font-family: $fontXWide;
		font-weight: 400;
		font-size: 32px;
		line-height: 32px;
		text-transform: uppercase;
	}

	.fxx__form__input {
		max-width: 480px;
		width: 100%;
		height: 48px;
		margin: 0 auto 16px auto;

		background: rgba(255, 255, 255, 0.25);
		color: #ffffff;
		border-radius: 48px;
		
		font-family: $fontXThin;
		font-weight: 700;
		font-size: 18px;
		text-align: center;

		text-transform: uppercase;

		&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
		  color: rgba(255, 255, 255, 0.5) !important;
		  opacity: 1; /* Firefox */
		}

		&:-ms-input-placeholder { /* Internet Explorer 10-11 */
		  color: rgba(255, 255, 255, 0.5) !important;
		}

		&::-ms-input-placeholder { /* Microsoft Edge */
		  color: rgba(255, 255, 255, 0.5) !important;
		}

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
		  -webkit-appearance: none;
		  margin: 0;
		  -moz-appearance: textfield;
		}
	}

	.fxx__form__input--count {
		position: relative;
	}

	.fxx__form__input--count__minus {
		position: absolute;
		top: 4px;
		left: 4px;

		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		width: 40px;
		height: 40px;
		border-radius: 50%;
		background: rgba(255, 255, 255, 0.1);

		cursor: pointer;

		&:hover {
			background: rgba(255, 255, 255, 0.25);
		}
	}

	.fxx__form__input--count__plus {
		position: absolute;
		top: 4px;
		right: 4px;

		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		width: 40px;
		height: 40px;
		border-radius: 50%;
		background: rgba(255, 255, 255, 0.1);

		cursor: pointer;

		&:hover {
			background: rgba(255, 255, 255, 0.25);
		}
	}

	.fxx__form__button {
		max-width: 480px;
		width: 100%;
		height: 48px;
		margin: 16px auto 0 auto;

		background: rgba(255, 255, 255, 1);
		color: #000000;
		border-radius: 48px;
		
		font-family: $fontXThin;
		font-weight: 900;
		font-size: 18px;

		text-transform: uppercase;
		cursor: pointer;
	}

	.fxx__response {
		max-width: 480px;
		margin: 24px auto 0 auto;
		font-size: 16px;
		line-height: 24px;

		a {
			border-bottom: 1px solid #fff;
		}
		// text-transform: lowercase;
	}
}

.fww__batons {
	width: 100%;
	max-width: 360px;

	margin: 0 auto;

	@media (min-width: 768px) {
		margin: -10vh auto 0 auto;
	}

	// img, video {
	// 	width: 100%;
	// }

	.fww__batons__main {
		width: 100%;
	}

	.fww__batons__background {
		position: fixed;
		top: -15vw;
		left: 0;
		z-index: -1;
		width: 100vw !important;
		height: auto;

		image-rendering: pixelated;

		outline: 10px solid red;

		filter: blur(144px);
		-webkit-filter: blur(200px);
		-moz-filter: blur(256px);
		-o-filter: blur(144px);
		-ms-filter: blur(144px);
	}
}

.fxx__success {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 480px;
	margin: 0 auto;
	padding: 0 0 48px 0;

	h2.fxx__content__description {
		margin: 32px auto 0 auto !important;
		line-height: 120%;
	}

	h4.fxx__response {
		width: 100%;
		max-width: 100%;
		margin: 16px auto 32px auto !important;
	}

	a {
		margin: 0 auto;
	}

	button {
		width: 320px;
		max-width: 100%;
		height: 40px;
		margin: 0 auto 16px auto;
		border-radius: 40px;
		color: #000;
		background: #fff;
		text-transform: uppercase;
		font-weight: 900;
	}
}

.fxx__title {
	font-size: 40px;
	text-transform: uppercase;
	font-weight: 700;
	font-family: $fontXWide;
}

.ogcrystals__logo {
	width: 100%;
	max-width: 480px;
}

.puma__logo {
	width: 100%;
	max-width: 144px;
	margin: 0 auto 8px auto;
}

.seedclub__logo {
	width: 100%;
	max-width: 480px;
}

.thecultivist__logo {
	width: 100%;
	max-width: 560px;
	margin: 0 auto 24px auto;
}

.pace__logo {
	width: 100%;
	max-width: 224px;
	margin: 0 auto 8px auto;
}

.wasserman__logo {
	width: 100%;
	max-width: 224px;
	margin: 0 auto 32px auto;
}

.hundreds__logo {
	width: 100%;
	max-width: 560px;

}

.hashed__logo {
	width: 100%;
	max-width: 320px;
	margin: 0 auto 8px auto;
}

.sandbox__logo {
	width: 100%;
	max-width: 320px;
	margin: 16px auto 0 auto;
}