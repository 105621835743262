@import 'variables.scss';
@import 'reset.scss';
@import 'fonts.scss';
@import 'form.scss';
@import 'modal.scss';

body {
  background-color: black;
  font-family: GTAmerica, Helvetica, Arial, sans-serif;
  color: $textColor;
}

p {
  font-size: 1em;
  line-height: 1.5em;
}

a,
button {
  cursor: pointer;
}

.ptb__mobile,
.splash-mobile {
  @media (min-width: $mobileWidth) {
    display: none;
  }
}

.ptb__desktop,
.splash-desktop {
  @media (max-width: $mobileWidth) {
    display: none;
  }
}

.ptb,
.social-button {
  &__input,
  &__button,
  &__inverted,
  &__discord--inverted,
  &__twitter--inverted,
  &__discord,
  &__twitter {
    font: 700 18px $fontXThin;
    color: #000;
    background-color: #fff;
    display: inline-block;
    line-height: 40px;
    border-radius: 99px;
    text-transform: uppercase;
    white-space: nowrap;
    text-align: center;
    min-width: 230px;
    padding: 0 15px;

    // @media (max-width: $mobileWidth) {
    //   transform: scale(1.3);
    // }
    &:disabled {
      opacity: 0.33;
    }
  }

  &__button--large {
    line-height: 60px;
    font-size: 25px;
      padding: 0 35px;
  }

  &__discord--inverted,
  &__twitter--inverted,
  &__discord,
  &__twitter {
    &:before {
      margin-right: 10px;
      content: '';
      display: inline-block;
      width: 20px;
      height: 40px;
      vertical-align: middle;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-image: url(../assets/images/logos/discord_logo.png);
    }
  }

  &__twitter,
  &__twitter--inverted {
    background-color: #000;
    border: 2px solid #fff;
    line-height: 36px;
    color: #fff;
    &:before {
      height: 36px;
      background-image: url(../assets/images/logos/twitter.png);
    }
  }

  &__twitter--inverted,
  &__discord--inverted {
    filter: invert(1);
  }

  &__inverted {
    background-image: none;
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
    line-height: 36px;
  }
}

.ptb__select-container {
  font: 700 16px $fontXThin;
  text-transform: uppercase;
  width: 180px;
  margin: 0 auto;

  .ptb__select {
    &__control {
      background-color: rgba(255, 255, 255, 0.25);
      border: none;
      box-shadow: none;
    }
    &__single-value {
      color: #fff;
    }
    &__menu {
      background-color: rgba(255, 255, 255, 0.25);
      color: #fff;
    }
    &__indicator-separator {
      background-color: transparent;
    }
    &__option {
      background-color: transparent;
      &--is-selected {
        background-color: rgba(255, 255, 255, 0.25);
      }
      &:hover {
        background-color: rgba(255, 255, 255, 0.5);
      }
    }
  }
}

.right-arrow-icon {
  background: url('/assets/images/right_arrow.svg') no-repeat 50% 50%;
  background-size: 16px;
  display: inline-block;
  width: 16px;
  height: 16px;
}

.down-arrow-icon {
  background: url('/assets/images/down_arrow.svg') no-repeat 50% 50%;
  background-size: 16px;
  display: inline-block;
  width: 16px;
  height: 16px;
}
