@import 'variables';

.faq {
  padding: 168px 144px;
  font-size: 18px;

  &__title {
    font: 900 48px $fontXWide;
    text-align: center;
    margin-bottom: 16px;
    text-transform: uppercase;
  }

  &__description {
    opacity: 0.8;
    margin-bottom: 56px;
    text-align: center;
  }

  &__item{
    margin-bottom: 32px;
    cursor: pointer;
    
    &.selected {
      .faq__question {
        opacity: 1;
        &:before {
          transform: rotate(90deg);
          top: 8px;
        }
      }
      .faq__answer {
        max-height: 500px;
        opacity: 0.8;
      }
    }
  }

  &__question {
    font: 700 28px $fontXThin;
    text-transform: uppercase;
    opacity: 0.5;
    margin-bottom: 12px;
    position: relative;
    &:before {
      content: url("/assets/images/right_icon.svg");
      width: 13px;
      height: 24px;
      display: block;
      position: absolute;
      top: 2px;
      left: -24px;
    }
  }

  &__helper {
    font-size: 16px;
    font-weight: 400px;
    opacity: 0.66;
  }

  &__answer {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 0.25s ease-in;
  }
}
