@import '../variables';

.splash-header {
  position: fixed;
  top: -1px; // solves slight whiteness bug
  left: 0;
  width: 100vw;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  font: 900 30px $fontXWide;
  transform: translate3d(0, -100%, 0);
  opacity: 0;
  display: flex;
  align-items: flex-start;
  padding: 0 10px;
  z-index: 10;
  height: 128px;
  padding-top: 20px;

  @media (max-width: $mobileWidth) {
    // background-color: rgba(0,0,0,1);
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 20%,
      rgba(0, 0, 0, 0) 100%
    );
    justify-content: center;
    padding: 0;
    height: 108px;
  }

  &__title {
    margin-left: 20px;
    font: 900 24px $fontXWide;
    text-transform: uppercase;
    color: #fff;
    flex: 1 1 auto;

    @media (max-width: 1200px) {
      a {
        width: 61px;
        display: inline-block;
      }
    }

    @media (max-width: $mobileWidth) {
      font-size: 13px;
      position: absolute;
      top: 8px;
      left: 0;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    margin-right: 20px;

    @media (max-width: $mobileWidth) {
      margin: 12px auto 0 auto;
      padding: 0 10px;
    }

    .ptb__button {
      @media (max-width: 400px) {
        min-width: auto;
      }
    }
  }

  &__discord {
    @media (max-width: $mobileWidth) {
      filter: invert(0) !important;
    }
  }

  &__links {
    font: 700 18px $fontXThin;
    white-space: nowrap;
    text-transform: uppercase;
    display: flex;
    line-height: 40px;

    a {
      margin-right: 40px;

      &.selected {
        text-decoration: underline;
      }
    }

    @media (max-width: $mobileWidth) {
      display: none;
      position: fixed;
      top: 60px;
      left: 0;
      right: 0;
      bottom: 0;
      background: #000;
      height: 100vh;
      a {
        font: 900 20px $fontXThin;
        margin: 0 0 32px;
        &:first-child {
          order: 5;
        }
      }
    }
  }

  &.mobile-nav--active {
    .splash-header {
      &__links {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
      }
      &__menu-button {
        background-image: url('/assets/images/close_button.svg');
        width: 16px;
        height: 16px;
        background-size: 16px;
      }
    }
  }

  &__button.connected .ptb__button {
    padding-left: 30px;
  }

  &__balance {
    &:before {
      content: url('/assets/images/logos/eth_logo.svg');
      width: 15px;
      height: 25px;
      display: block;
      position: absolute;
      left: -20px;
      top: 1px;
    }
    display: inline-block;
    margin-right: 8px;
    position: relative;
    font-weight: 400;
  }

  &__menu-button {
    @media (max-width: $mobileWidth) {
      position: absolute;
      top: 8px;
      right: 8px;
      background: url('/assets/images/menu_icon.png') no-repeat 50%;
      background-size: 24px;
      width: 24px;
      height: 24px;
      padding: 24px;
      cursor: pointer;
    }
  }
}

.splash-header__title--mobile {
  position: fixed;
  top: 24px;
  margin: 0;
  font: 900 24px $fontXWide;
  text-transform: uppercase;
  color: #fff;
  width: 100%;
  text-align: center;
}
