@import "variables.scss";
@import "reset.scss";
@import "fonts.scss";

.progress {
  &__background {
    background-color: rgba(255,255,255,0.33);
    border-radius: 99px;
    position: relative;
    overflow: hidden;
  }

  &__current {
    text-align: center;
    font-weight: 700;
    font-family: $fontXThin;
    background-color: #ffffff;
    border-radius: 99px;
    color: #000000;
    overflow: hidden;
    white-space: nowrap;
    transition: width 0.5s ease-in-out;

    &.level-common {
      background-color: #fff;
    }

    &.level-bronze {
      background: linear-gradient(180deg, #CA9671 0%, #FFC69C 25%, #9A633C 50.52%, #A2714D 76.56%, #613C21 100%);
    }

    &.level-silver {
      background: linear-gradient(180deg, #7A7A7A 0%, #EFEFEF 25%, #808080 48.96%, #9E9E9E 75%, #414141 100%);
    }

    &.level-gold, &.level-black {
      background: linear-gradient(180deg, #83772F 0%, #FFFFA8 25%, #FAEA87 50.52%, #C6B768 76.56%, #211406 100%);
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  &__title {
    font: 700 16px $fontXThin;
    text-transform: uppercase;
  }
  
  &__subtitle {
      font: 700 16px $fontXThin;
      text-transform: uppercase;
      color: rgba(255,255,255,0.75);
  }

  &__text {
    font-family: $fontXThin;
    font-weight: 700;
    position: absolute;
    left: 12px;
    top: 40%;
    line-height: 100%;
    transform: translateY(-40%);
    color: #000000;
  }
}