@import '../variables';

.splash-about {
  position: relative;
  width: 100vw;
  padding: 50px 0;

  @media (max-width: $mobileWidth) {
    padding-top: 0;
  }

  &__canvas {
    width: 90%;
    position: absolute;
    top: 0;
    left: 0;

    @media (max-width: $mobileWidth) {
      position: static;
      width: 100vw;
    }
  }

  &__title {
    font: 900 50px $fontXWide;
    text-transform: uppercase;
    margin-bottom: 20px;
    text-align: center;
  }

  &__byline {
    font: 500 24px $fontXThin;
    color: $textColor;
    text-align: center;
    margin-bottom: 50px;
    span {
      text-transform: uppercase;
    }
  }

  &__row {
    display: flex;
    width: 70vw;
    margin: auto;
    justify-content: flex-end;

    @media (max-width: 1280px) {
      width: calc(100vw - 80px);
    }

    @media (max-width: $mobileWidth) {
      display: block;
      width: auto;
    }
  }

  &__col {
    position: relative;

    &:nth-child(1) {
        width: 40%;
      }
      
      &:nth-child(2) {
        width: 60%;
      }

    @media (max-width: 1280px) {
      &:nth-child(1) {
        width: 30%;
      }

      &:nth-child(2) {
        width: 70%;
      }
    }

    @media (max-width: $mobileWidth) {
      display: block;
      width: auto;
      
      &:nth-child(1),
      &:nth-child(2) {
        width: auto;
      }
    }
  }

  &__profile {
    width: 60%;
    flex: 0 0 auto;
    display: flex;
    align-items: flex-start;

    @media (max-width: $mobileWidth) {
      margin: 20px 40px;
      width: auto;
      display: block;
    }

    @media (max-width: 560px) {
      align-items: flex-start;
      flex-direction: column;
    }

    img {
      @media (max-width: $mobileWidth) {
        float: none;
        max-width: 340px;
        margin: 0 auto 20px;
      }

      @media (max-width: 720px) {
        max-width: 256px;
      }

      @media (max-width: 560px) {
        max-width: 128px;
      }
    }

    &:last-child {
      float: right;

      @media (max-width: $mobileWidth) {
        padding-top: 16px;
        left: auto;
      }
    }
  }

  &__profile-image {
    flex: 1;
    padding-right: 30px;

    @media (max-width: $mobileWidth) {
      flex: 0 auto;
      padding: 0;
    }
  }

  &__profile-summary {
    flex: 1;
    padding-bottom: 20px;

    @media (max-width: $mobileWidth) {
      padding-bottom: 0;
    }
  }

  &__link {
    clear: both;
    text-align: center;
    @media (max-width: $mobileWidth) {
      padding: 40px;
    }
  }

  &__learn-more {
    position: absolute;
    // left: calc(50% + 20px);
    left: calc(40% + 40px);
    margin: 24px 0 0 0;

    @media (max-width: 1280px) {
      left: calc(28% + 40px);
    }

    a {
      border-bottom: 2px solid rgba(255, 255, 255, 0);

      &:hover {
        border-bottom: 2px solid rgba(255, 255, 255, 1);
      }
    }

    h3 {
      display: inline-block;
      margin: 0 0 48px 0;
      line-height: 1;
      padding: 4px 0;
      font-weight: 700;
    }

    @media (max-width: $mobileWidth) {
      position: relative;
      top: 24px;
      left: 0;
      margin: 0 0 0 40px;
    }
  }
}
