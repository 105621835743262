@import '../variables';
.splash-how {
  width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  // padding: 128px 0;
  margin: 0;
  padding: 176px 0 144px 0;

  @media (max-width: $mobileWidth) {
    display: block;
    padding-bottom: 120px;
  }

  &__col {
    position: relative;
    padding: 250px 15vw 0 30px;
    flex: 1;

    @media (max-width: $mobileWidth) {
      padding: 0 30px;
    }
    &:first-child {
      padding: 0 30px 250px 15vw;

      @media (max-width: $mobileWidth) {
        padding: 0 30px;
      }
    }

  }

  &__title {
    font: 900 $titleSize $fontXWide;
    text-transform: uppercase;
    margin-bottom: 20px;
    margin-left: -30px;

    @media (max-width: $mobileWidth) {
      margin-left: 0;
      font-size: $titleSizeMobile;
    }
  }

  &__step {
    display: flex;
    margin-bottom: 20px;

    @media (max-width: $mobileWidth) {
      display: block;
    }
  }

  &__num {
    font: 900 24px $fontXWide;
    flex: 0 0 30px;
    margin: 10px 20px 0 0;
    line-height: 1;
    display: inline-block;
  }

  &__bullet-title {
    margin: 0 0 10px 0;
    font: 900 24px $fontXWide;
    line-height: 1;
    text-transform: uppercase;
    // margin-bottom: 10px;

    @media (max-width: $mobileWidth) {
      margin: -2px 0 12px 0;
    }
  }

  &__text {
    margin-top: 10px;

    @media (max-width: $mobileWidth) {
      margin-bottom: 32px;
    }
  }

  &__baton-divider {
    @media (max-width: $mobileWidth) {
      text-align: center;
      height: 100px;
      position: relative;
      margin: 15px 0;
    }
  }

  &__baton--left,
  &__baton--right {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 200px;
    z-index: -1;
    max-width: none;

    @media (max-width: $mobileWidth) {
      height: 100px;
    }
  }

  &__baton--right {
    bottom: auto;
    right: auto;
    left: 0;
    top: 0;

    @media (max-width: $mobileWidth) {
      float: right;
    }
  }

  &__bottom-batons {
    margin: 48px 0 0 0;
  }
}