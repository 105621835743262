.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  padding: 32px;
  width: 504px;
  background-color: #333;

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }

  &__close {
    background-image: url('/assets/images/close_button.svg');
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 12px 12px;
    position: absolute;
    top: 0;
    right: 0;
    width: 12px;
    height: 12px;
    padding: 22px;
    cursor: pointer;
    opacity: 0.5;
  }

  &__title {
    font: 700 32px/32px $fontXThin;
    text-transform: uppercase;
    margin-bottom: 16px;
  }

  &__description {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
  }

  &__list-item {
    font: 16px $fontXThin;
    text-transform: uppercase;
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__centered {
    display: flex;
    align-items: center;
    justify-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 22px;
    text-align: center;
  }

  &__centered &__content {
    flex: 1 1;
  }

  &__link {
    font-size: 14px;
    font-weight: 700;
    text-decoration: underline;
    text-transform: uppercase;
    opacity: 0.8;
  }

  &__error {
    margin: 8px 16px;
    color: #f00;
  }
}

.modal.donate {
  min-height: 440px;

  .modal__list-item span:first-child {
    margin-right: 4px;
  }
  .donate-member {
    opacity: 0.5;
  }

  .form__row.donation {
    margin-top: 32px;
    margin-bottom: 16px;

    .helperText {
      font: 700 18px $fontXThin;
      position: absolute;
      right: 32px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  input[name='donation'] {
    padding-right: 72px;
  }

  &.scrollable {
    .modal__list {
      padding: 0 0 0 16px;
      background-color: rgba(255, 255, 255, 0.05);
      border-radius: 8px;
      &-inner {
        max-height: 300px;
        overflow-y: scroll;
      }
      &-item {
        &:first-child {
          margin-top: 16px;
        }
        &:last-child {
          margin-bottom: 16px;
        }
      }
    }
  }

  &.donated {
    text-align: center;
    .modal__title {
      font-size: 24px;
      line-height: 24px;
    }
    .modal__description {
      opacity: 0.66;
    }
    button {
      margin: 32px 0 26px;
      width: 244px;
    }
  }
}

.modal.connect {
  width: 280px;
  padding: 16px;

  .modal__title {
    font-size: 18px;
  }
  .modal__buttons button {
    width: 100%;
    margin-bottom: 16px;
    background: #444;
    border: 1px solid rgba(255, 255, 255, 0.33);
    border-radius: 8px;
    padding: 16px 20px;
    text-transform: uppercase;
    font: 700 16px $fontXThin;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    img {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      height: 40%;
    }
  }
  .modal__description {
    margin-bottom: 32px;
  }
}

.modal.mint-black-confirmation {
  text-align: center;
  padding-top: 72px;
  padding-bottom: 72px;
  .modal__title {
    font: 700 24px $fontXThin;
    margin-bottom: 12px;
  }
  .modal__description {
    font-size: 16px;
    margin-bottom: 32px;
    opacity: 0.66;
  }
}

.modal.pending-transaction {
  min-height: 440px;
}

@media screen and (max-width: 540px) {
  .modal {
    width: 90%;
    &__error {
      display: block;
      margin: 8px 0;
    }
  }
}