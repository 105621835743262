@import '../variables';

.splash-intro {
  margin-top: 40px;
  text-align: center;
  width: 100vw;
  overflow: hidden;
  padding-bottom: 75vh;

  @media (max-width: $mobileWidth) {
    padding-bottom: 30vh;
    margin-top: 60px;
  }

  &__inner {
    position: relative;
    min-height: 95vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__canvas {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: 100vw;
    min-height: 100vh;
    z-index: -2;
  }

  &__byline {
    font: 500 24px $fontXThin;
    color: $colorGray;
    padding: 80px 20px 20px;
    flex: 0 0 auto;

    span {
      text-transform: uppercase;
    }

    @media (max-width: $mobileWidth) {
      padding: 24px 20px 20px;
      font-size: 18px;
    }
  }

  &__center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__content {
    margin: auto;
    text-transform: uppercase;
    display: inline-block;
  }

  &__col {
    margin: 0;
    font: 500 24px $fontXThin;
    color: $colorGray;

    @media (max-width: $mobileWidth) {
      font-size: 20px;
    }
  }

  &__title {
    font-family: $fontXWide;
    font-weight: 900;
    font-size: 70px;
    line-height: 70px;
    margin-bottom: 15px;

    br {
      display: none;
    }

    @media (max-width: $mobileWidth) {
      margin: 20vh 0 0 0;
      padding: 0 20px;
      font-size: 48px;
      line-height: 1.07;

      br {
        display: inline;
      }
    }
  }

  &__button {
    margin: 0 15px;
    display: inline-block;

    @media (max-width: $mobileWidth) {
      display: block;
      margin-top: 15px;
      // margin-top: 30px;
    }
  }

  &__footer {
    flex: 0 0 auto;
    padding-bottom: 75px;

    img {
      vertical-align: middle;
      margin: 0 20px;
      @media (max-width: $mobileWidth) {
        margin: 0 3vw;
      }
    }
  }

  &__logo-hashed {
    height: 20px;
    @media (max-width: $mobileWidth) {
      height: 4vw;
    }
  }

  &__logo-visibility {
    height: 15px;
    @media (max-width: $mobileWidth) {
      height: 3vw;
    }
  }

  &__logo-pace {
    height: 25px;
    @media (max-width: $mobileWidth) {
      height: 5vw;
    }
  }

  &__logo-unopnd {
    height: 25px;
    @media (max-width: $mobileWidth) {
      height: 5vw;
    }
  }}