@import '../variables';

.splash-footer {
  margin-top: 100px;
  padding-bottom: 50px;

  @media (max-width: $mobileWidth) {
      margin-top: 32px;
  }

  &__marquee-container {
    height: 80px;
  }

  &__supported-by {
    position: absolute;
    // top: 2px;
    z-index: 15;
    bottom: 15px;
    left: 0px;

    width: auto;
    white-space: nowrap;
    
    margin: 0;
    padding: 0 64px 0 16px;
    text-transform: uppercase;
    font-family: $fontXThin;
    font-weight: 700;
    font-size: 20px;
    line-height: 1;
    background: linear-gradient(90deg, rgba(0,0,0,1) 50%, rgba(0,0,0,0) 100%);
  }

  &__cover-right {
    position: absolute;
    // top: 2px;
    z-index: 15;
    bottom: 15px;
    right: 0px;

    display: block;
    width: 128px;
    height: 20px;
    background: linear-gradient(-90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
    // border: 1px solid red;
  }

  &__marquee {
    position: fixed;
    width: 100vw;
    bottom: 0;
    left: 0;
    line-height: 50px;
    height: 80px;
    z-index: 10;

    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 85%);

    &--inline {
      // position: static;
      position: relative;
    }
  }

  &__names {
    display: inline;
    font: 400 20px $fontXThin;
    text-transform: uppercase;
    overflow: hidden;
    white-space: nowrap;
    margin: 30px 0 0 0;
    height: 50px;
    line-height: 50px;

    p {
      display: inline-block;
      margin: 0 30px;
    }
  }

  &__divider {
    margin: 30px 40px;
    border-top: 1px solid #fff;
  }

  &__row {
    display: flex;
    margin: 0 40px;
  }

  &__col {
    flex: 1;
    font-size: 14px;

    &:last-child {
      text-align: right;
    }
  }

  &__title {
    font: 500 20px $fontXThin;
    text-transform: uppercase;
    padding-bottom: 30px;
  }

  &__list {
    &--item,
    li {
      text-transform: uppercase;
      font: 300 20px $fontXThin;
      padding: 6px 0;
    }
  }

  &__social {
    img {
      height: 20px;
      margin-left: 20px;

      &:first-child {
        margin-right: 7px;
      }
    }
  }

  &__copyright {
    font: 300 20px $fontXThin;
    text-transform: uppercase;
    margin-top: 20px;
  }

  &__terms {
    text-align: center;
    margin: 16px 0;
    font: 400 18px $fontXThin;
    text-transform: uppercase;
    a {
      margin: 0 16px;
    }
  }
}