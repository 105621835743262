@import '../variables';

.gallery {
  &__evolution-states {
    display: flex;
    justify-content: flex-start;
  }

  &__evolution-option {
    height: 32px;
    line-height: 32px;
    font: 700 20px $fontXThin;
    color: rgba(255,255,255,0.4);
    text-transform: uppercase;
    border-radius: 99px;
    padding: 0;
    margin: 5px 20px 5px 0;
    vertical-align: middle;

    .checkbox {
      display: inline-block;
      width: 18px;
      height: 18px;
      line-height: 32px;
      border: 1px solid rgba(255,255,255,0.5);
      border-radius: 4px;
      margin-right: 8px;
      position: relative;
    }
    
    &.selected {
      color: #fff;

      .checkbox:before {
        content: "";
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 2px;
        background: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}