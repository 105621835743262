@import '../variables';

.coloredbar {
  position: relative;

  @media (min-width: $phoneWidth) and (max-width: 1400px) {
    margin-left: 100px;
  }

  &__bar {
    border-radius: 99px;
    background-color: rgba(255,255,255,0.33);
    overflow: hidden;
  }

  &__progress {
    height: 16px;
    border-radius: 99px;

    &.level0 {
      background-color: #fff;
    }

    &.level1 {
      background: linear-gradient(180deg, #CA9671 0%, #FFC69C 25%, #9A633C 50.52%, #A2714D 76.56%, #613C21 100%);
    }

    &.level2 {
      background: linear-gradient(180deg, #7A7A7A 0%, #EFEFEF 25%, #808080 48.96%, #9E9E9E 75%, #414141 100%);
    }

    &.level3 {
      background: linear-gradient(180deg, #83772F 0%, #FFFFA8 25%, #FAEA87 50.52%, #C6B768 76.56%, #211406 100%);
    }

    &.level4 {
      background: linear-gradient(180deg, #3A3A3A 0%, #AFAFAF 25%, #404040 48.96%, #5E5E5E 75%, #000000 100%);
    }
  }

  &__labels {
    display: flex;
  }

  &__labels-mobile {
    display: flex;
    justify-content: space-between;
    font: 400 14px $fontXThin;
    margin-bottom: 10px;

    @media (min-width: $phoneWidth) {
      display: none;
    }
  }

  &__label {
    text-align: center;
    flex: 0 0 auto;
    position: relative;
    white-space: nowrap;
    height: 20px;
    line-height: 20px;

    &:nth-child(1) {
      width: 20%
    }
    &:nth-child(2) {
      width: 33%
    }
    &:nth-child(3) {
      width: 41%
    }
    &:nth-child(4) {
      flex: 1 0 auto;
    }

    @media (max-width: 1400px) {
      text-align: left;

      > span:first-child {
          display: none;
      }
    }
  }

  &__mobile {
    font: 400 14px $fontXThin;
    margin-top: 5px;
    display: none;
    @media (min-width: $phoneWidth) and (max-width: 1400px) {
      display: block;
    }
  }

  &__line {
    position: absolute;
    bottom: -50px;
    right: 0;
    height: 200px;
    border-right: 1px dashed rgba(255,255,255,0.5);

    @media (max-width: 1400px) {
      bottom: 15px;
      height: 30px;
    }
  }

}