@import 'variables.scss';

.partners {
  padding: 120px 40px 50px;

  &__title {
    margin: 0 0 16px 0;
    font: 900 $titleSize $fontXWide;
    color: $colorDarkGray;
    text-align: center;
    text-transform: uppercase;
    line-height: 1.1;
  }

  &__subtitle {
    text-align: center;
    color: $colorDarkGray;
    margin: 0 auto 56px auto;
  }

  &__list {
    max-width: 1024px;
    margin: auto;
  }


  &__row {
    font: 500 30px $fontXThin;
    text-transform: uppercase;
    margin: 20px 0;
  }

  &__action {
    position: relative;
    display: inline-flex;
    flex-direction: row;
    cursor: pointer;
    margin: 0 -4px;
    padding: 0 4px;
    // &:after {
    //   content: '';
    //   position: absolute;
    //   top: 50%;
    //   left: -4px;

    //   // width: calc(100% + 8px);
    //   width: 0;
    //   height: 2px;
    //   background: #ffffff;
    // }

    background: #000000;
    color: #ffffff;

    // transition: all 50ms ease-in-out;


    &:hover {
      background: #ffffff;
      color: #000000;
    }


    @media (max-width: $mobileWidth) {
      flex-direction: column;
    }
  }


  &__name {
    font: 700 30px $fontXThin;
    margin-right: 10px;
  }

  &__description {
    color: $colorDarkGray;
  }
}


.partners-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999999999;
  // background: rgba(0, 0, 0, 0.33);

  padding: 0;
  // display: flex;
  // justify-content: center;

  overflow-y: scroll;
}

.popup-close-wrapper {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  width: 100%;
}

.partners-popup__close {
  cursor: pointer;
}

.partners-content {
  position: absolute;
  z-index: 10000;
  top: 10vh;
  left: 50%;
  transform: translateX(-50%);

  width: calc(100vw - 32px);
  max-width: 960px;
  height: auto;
  background: #ffffff;
  padding: 32px;
  border-radius: 2px;
  color: #000;

  @media (max-width: $mobileWidth) {
    width: calc(100vw - 32px);
    padding: 24px;
  }
}

.partners-content__split {
  display: flex;
  flex-direction: row;
  gap: 40px;

  @media (max-width: $mobileWidth) {
    flex-direction: column;
  }
}

.pc__group {
  flex: 1;

  .pc__group__img {
    max-width: 280px;
    width: 100%;
    height: 200px;
    margin: 0 0 28px 0;
    background-size: contain;
    background-position: left center;
    background-repeat: no-repeat;
  }

  .pc__group__title {
    margin: 0 0 16px 0;
    font: 900 24px $fontXThin;
    text-transform: uppercase;
  }

  p {
    font-size: 15px;

    span {
      display: block;
      margin: 16px 0;
    }

    ul, li {
      list-style: normal;
    }

    b {
      font-weight: 700;
    }

    li {

      margin: 0 0 8px 0;
    }
  }
}

.partners-popup__underlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100vw;
  height: 100vw;
  background: rgba(0, 0, 0, 0.33);
  backdrop-filter: blur(24px);
}