@import '../variables';

.gallery {

  &__header,
  &__row {
    display: flex;
    align-items: center;
    font: 700 18px $fontXThin;
    text-transform: uppercase;
    color: rgba(255,255,255,0.5);
    padding-bottom: 16px;
    overflow: hidden;

    @media (max-width: $phoneWidth) {
      display: block;
    }

    img {
      height: 72px;
    }

    > span {
      white-space: pre-wrap;
      flex: 0 0 auto;
      @media (max-width: $phoneWidth) {
        display: block;
      }

      &:nth-child(1) {
        flex-basis: 100px;
      }

      &:nth-child(2) {
        flex-basis: 130px;
      }
      &:nth-child(3) {
        flex-basis: 120px;
      }
      &:nth-child(4) {
        flex-basis: 150px;
      }
      &:nth-child(5) {
        flex: 1 0 240px;
        text-align: right;
      }

      @media (max-width: $phoneWidth) {
        &:nth-child(2) {
          float: left;
        }
        &:nth-child(3) {
          display: none;
        }
        &:nth-child(4) {
          text-align: right;
        }
      }

    }
  }

  &__row {
    color: rgba(255,255,255,0.8);
    cursor: pointer;

    strong {
      color: #ffffff;
      font-size: 18px;
    }

    > span {
      &:nth-child(2) {
        font-size: 16px;
      }
    }

    @media (max-width: 1400px) {
      flex-wrap: wrap;
      > span:nth-child(5) {
        flex: 0 0 auto;
        width: 100%;
      }
      
    }
  }

}