@import "variables.scss";
@import "reset.scss";
@import "fonts.scss";

.drawing {
  padding: 160px 80px;

  @media (max-width: $mobileWidth) {
    padding: 100px 20px;
  }

  &__content {
    max-width: 600px;
    margin: 0 auto;
  }

  &__title {
    font: 900 40px $fontXWide;
    text-transform: uppercase;

    @media (max-width: $mobileWidth) {
      font-size: 24px;
    }
  }

  &__image {
    width: 100%;
    margin-top: 66px;
  }

  &__button {
    margin-top: 54px;
  }

  &__claimable {
    color: $colorGray;
    font-size: 12px;
    margin-top: 10px;
  }

  &__description {
    font: 400 18px $font;
    margin-top: 71px;

    p {
      margin-top: 20px;
    }

    @media (max-width: $mobileWidth) {
      font-size: 16px;
    }
  }
}