@import '../variables';

.gallery {
  &__total {
    position: relative;
    top: -16px;
    border: 1px solid #fff;
    padding: 10px;
  }

  &__total-title {
    font: 900 32px $fontXWide;
    text-transform: uppercase;
  }

  &__total-subtitle {
    margin: 5px 0 0 0;
    font: 900 27px $fontXThin;
    text-transform: uppercase;
    opacity: 0.75;
  }

  &__totals {
    margin-top: 5px;
    font: 700 32px $fontXThin;
    display: flex;
    justify-content: space-between;
  }
}