$batonCutoffWidth: 1520px;

@import '../variables';
.splash-np {
  position: relative;
  padding: 100px 0;

  @media (max-width: $mobileWidth) {
    padding-bottom: 0;
  }

  &__bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;

    img {
      position: absolute;
      right: 2vw;
      height: 200vh;
      max-height: 200vh;
      top: 50%;
      transform: translateY(-50%);
    }

    @media (max-width: $batonCutoffWidth) {
      display: none;
    }
  }

  &__title {
    font: 900 $titleSize $fontXWide;
    text-transform: uppercase;
    margin: 0 22vw 30px 30vw;

    @media (max-width: $batonCutoffWidth) {
      margin-right: 10vw;
    }

    @media (max-width: $mobileWidth) {
      margin: 0 30px 24px 30px;
      font-size: 7vw;
    }
  }

  &__content {
    width: 70vw;
    margin: auto;
    display: flex;
    justify-content: center;

    @media (max-width: $mobileWidth) {
      display: block;
      width: auto;
    }

    a {
      border-bottom: 2px solid rgba(255, 255, 255, 0);

      &:hover {
        border-bottom: 2px solid rgba(255, 255, 255, 1);
      }
    }

    h3 {
      display: inline-block;
      margin: 0 0 48px 0;
      line-height: 1;
      padding: 4px 0;
      font-weight: 700;
      // font: 900 18px $fontXThin;
      // text-transform: uppercase;
    }
  }

  &__gallery {
    position: relative;
    flex: 1;
    height: 30vw;
    margin-right: 30px;

    @media (max-width: $mobileWidth) {
      height: auto;
      margin: 0 30px 30vw 0;
    }
  }

  &__description {
    flex: 1;
    margin-left: 30px;

    p {
      width: 80%;
      margin-bottom: 30px;
    }

    @media (max-width: $mobileWidth) {
      // margin-right: 0;
      margin-right: 30px;

      p {
        width: auto;
        // margin-right: 30px;
        margin-right: 0;
      }
    }
  }

  &__learn {
    margin-bottom: 30px;

    @media (max-width: $mobileWidth) {
      text-align: center;
    }
  }

  &__footer {
    position: relative;
    display: flex;
    align-items: flex-start;

    @media (max-width: $mobileWidth) {
      left: auto;
      display: block;
      flex-wrap: wrap;
      padding-bottom: 100px;
    }
  }


  &__logo {
    vertical-align: top;
    width: 110px;
    margin-left: 48px;
    flex: 0 1 auto;

    &:nth-of-type(1) {
      margin: 0 0 0 20px;
    }
  }

  &__gallery-top {
    width: 100%;
  }

  &__gallery-bottom {
    width: 60%;

    position: absolute;
    right: -30px;
    bottom: 0;

    @media (max-width: $mobileWidth) {
      margin-bottom: 20px;
      width: 60vw;
      bottom: -10vw;
    }
  }
}