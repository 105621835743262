@import 'variables.scss';
@import 'reset.scss';
@import 'fonts.scss';

.evolutions {
  padding: 100px 128px 120px 144px;

  &__title {
    font: 900 48px $fontXWide;
    margin: 16px 0;
    text-transform: uppercase;
  }

  &__description,
  &__description-mobile,
  &__section-description {
    font-size: 18px;
    margin-bottom: 48px;
    opacity: 0.8;
  }

  &__bottom-section-description {
    text-align: center;
  }

  &__description-mobile {
    display: none;
  }

  &__subtitle {
    font: 900 40px $fontXWide;
    text-transform: uppercase;
    margin-bottom: 16px;
  }

  &__section-title {
    font: 700 28px $fontXThin;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    margin-bottom: 6px;
    span {
      flex: 1 1 auto;
      &:last-child {
        text-align: right;
        opacity: 0.8;
      }
    }
  }

  &__states {
    margin-top: 10px;
    color: rgba(255, 255, 255, 0.33);

    span {
      font: 700 24px/24px $fontXThin;
      text-transform: uppercase;
    }
    .right-arrow-icon {
      margin: 0 4px;
      opacity: 0.33;
    }
  }

  &__bar {
    display: flex;
    background-color: rgba(255,255,255,0.33);
    border-radius: 99px;

    @media (max-width: $mobileWidth) {
      display: none;
    }
  }

  &__bar-text {
    font: 400 16px/16px $fontXThin;
    flex: 0 1 auto;
    text-align: center;

    &:nth-child(1) { width: 19% }
    &:nth-child(2) { width: 26% }
    &:nth-child(3) { width: 45% }
    &:nth-child(4) { width: 10% }
  }

  &__milestones {
    display: flex;
    margin: 53px 0 320px;

    @media (max-width: $mobileWidth) {
      display: none;
    }
  }

  &__milestone {
    flex: 0 1 auto;
    position: relative;

    &:nth-child(1) { width: 19% }
    &:nth-child(2) { width: 26% }
    &:nth-child(3) { width: 45% }
    &:nth-child(4) { width: 10% }
  }

  &__milestone-line {
    border-left: 1px dashed rgba(255,255,255,0.5);
    position: absolute;
    top: -100px;
    left: 0;
    height: 80px;
    width: 1px;
  }

  &__milestone-description {
    font: 700 24px $fontXThin;
    text-transform: uppercase;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__milestone-image {
    width: 100px;
    margin: 0 auto;
  }

  &__milestone:nth-child(n + 2) &__milestone-description {
    text-align: center;
    transform: translateX(-50%);
  }

  &__milestone-subtitle {
    font-size: 18px;
    color: rgba(255,255,255,0.66);
    white-space: nowrap;
  }

  &__donations {
    display: none;
    flex-direction: row;
    margin: 120px 0 193px;

    @media (max-width: $mobileWidth) {
      display: flex;
      flex-direction: column;
      margin-bottom: 150px;
    }

    .baton {
      margin: 0 0 0 auto;
      display: flex;
      flex-direction: row;
      flex: 0 1 auto;
      @media (max-width: $mobileWidth) {
        align-items: flex-end;

        .image {
          transform: translateY(50%);
          width: 88px;
        }
      }
      .name {
        flex: 1 1;
        font: 700 24px $fontXThin;
        text-transform: uppercase;
        padding-left: 32px;
      }
    }

    .arrow  {
      flex: 1 0 auto;
      width: 7vw;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.33;

      @media (max-width: $mobileWidth) {
        display: none;
      }

      .right-arrow-icon {
        width: 32px;
        height: 32px;
        background-size: cover;
      }
    }

    .section {
      margin-bottom: 76px;
    }
   }

  &__black {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .baton {
      flex: 1 1 25%;
      padding: 32px;
      text-align: center;
      .title {
        font: 700 24px $fontXThin;
        opacity: 0.8;
        text-transform: uppercase;
        margin: 32px 0 8px;
      }
      .subtitle {
        font: 700 16px $fontXThin;
        opacity: 0.5;
        text-transform: uppercase;
      }
    }
  }
  &__black-border {
    border: 2px solid rgba(255, 255, 255, 0.33);
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    height: 50px;
    border-top: none;
    position: relative;
    &:after {
      content: "";
      background: rgba(255, 255, 255, 0.33);
      width: 2px;
      height: 50px;
      display: block;
      position: absolute;
      bottom: -52px;
      left: 50%;
    }
  }
  &__black-border-title {
    font: 900 24px $fontXWide;
    text-transform: uppercase;
    text-align: center;
    margin: 60px 0;
  }
  &__black-baton {
    margin: 0 auto;
    max-width: 490px;
  }
}

.evolutions-mobile {
  &__details {
    display: none;
    flex: 0 0 auto;
    width: 60vw;
    justify-content: center;
    position: relative;

    @media (max-width: $mobileWidth) {
      display: flex;
    }
  }

  &__milestone-description {
    flex: 1 0 auto;
    padding-left: 30px;
    display: flex;
    align-items: flex-end;
  }

  &__milestone-description-text {
    transform: translateY(50%);
  }

  &__milestone-title {
    font: 700 20px $fontXThin;
    text-transform: uppercase;
  }

  &__milestone-subtitle {
    font: 700 16px $fontXThin;
    color: rgba(255,255,255,0.66);
    text-transform: uppercase;
  }

  &__milestone-line {
    position: absolute;
    width: 100px;
    border-bottom: 1px dashed rgba(255,255,255,0.5);
    bottom: 0;
    right: -20px;
  }

  &__bar-text {
    font: 400 16px $fontXThin;
    color: rgba(255,255,255,0.5);
    flex: 0 0 auto;
    width: 60px;
    display: flex;
    align-items: center;
  }

  &__bar {
    width: 16px;
    background-color: rgba(255,255,255,0.33);
  }

  &__details[data-index="0"] &__bar {
    background-color: transparent;
    height: 50px;
  }

  &__details[data-index="1"] &__bar {
    height: 92px;
    border-radius: 99px 99px 0 0;
  }

  &__details[data-index="2"] &__bar {
    height: 142px;
  }

  &__details[data-index="3"] &__bar {
    height: 252px;
  }

  &__details[data-index="4"] &__bar {
    height: 35px;
    border-radius: 0 0 99px 99px;
  }
}

@media only screen and (max-width: 1366px) {
  .evolutions {
    padding-right: 64px;
    &__donations {
      .left {
        padding-right: 32px;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .evolutions {
    padding-right: 64px;
    &__donations {
      .left, .right {
        flex: 1 1 100%;
      }
      .left {
        padding-right: 0;
      }
      .right {
        .baton {
          margin: 0 auto;
          max-width: 480px;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .evolutions {
    padding-left: 96px;
    padding-right: 64px;
  }
}

@media only screen and (max-width: 480px) {
  .evolutions {
    padding-left: 16px;
    padding-right: 16px;

    &__states {
      display: none;
    }
    
    &__title {
      font-size: 36px;
    }
    
    &__subtitle {
      font-size: 28px;
    }

    &__donations {

      .right .baton {
        .image {
          flex: 0 0 60%;
        }
        .name {
          margin-bottom: 16px;
          padding-left: 0;
        }
      }
    }

    &__section-title {
      flex-direction: column;
      font-size: 24px;

      span:last-child {
        text-align: left;
        font-size: 18px;
        margin: 8px 0 12px;
      }
    }

    &__section-description {
      line-height: 24px;
      margin-bottom: 20px;
    }

    &__black {
      flex-direction: column;
      .baton {
        display: flex;
        flex-direction: row;
        flex: 1 1 100%;
        padding: 16px 0;
        .image {
          flex: 0 0 50%;
        }
        .text {
          text-align: left;
          margin-left: 32px;
          .title {
            font-size: 16px;
            margin: -12px 0 4px;
          }
          .subtitle {
            font-size: 16px;
          }
        }
      }
    }
  }
}
