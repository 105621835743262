@import '../variables';

.splash-hero {
  position: fixed;
  top: 56px;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -2;
  text-align: center;
  overflow: hidden;

  &__baton {
    position: absolute;
    width: 60vw;
  }

  &__tommie {
    width: 50vw;
  }

  &__black {
    top: 30vh;
    left: 0;
    width: 80vw
  }

  &__orange {
    top: 55vh;
    right: 0;
    width: 80vw
  }

  &__gold {
    top: 100vh;
    right: 10vw;
  }

  &__purple {
    top: 145vh;
    left: 20vw;
  }

  &__silver-blur {
    top: 190vh;
    right: 60vw;
    width: 20vw;
  }

  &__orange-blur {
    top: 235vh;
    left: 35vw;
    width: 30vw;
  }
}