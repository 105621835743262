@mixin gt-america($name, $file) {
  @font-face {
    font-family: $name;
    font-weight: 100;
    src: url("../assets/fonts/GT-America/#{$file}-Ultra-Light.OTF") format("opentype");
  }

  @font-face {
    font-family: $name;
    font-weight: 100;
    font-style: italic;
    src: url("../assets/fonts/GT-America/#{$file}-Ultra-Light-Italic.OTF") format("opentype");
  }

  @font-face {
    font-family: $name;
    font-weight: 200;
    src: url("../assets/fonts/GT-America/#{$file}-Thin.OTF") format("opentype");
  }

  @font-face {
    font-family: $name;
    font-weight: 200;
    font-style: italic;
    src: url("../assets/fonts/GT-America/#{$file}-Thin-Italic.OTF") format("opentype");
  }

  @font-face {
    font-family: $name;
    font-weight: 300;
    src: url("../assets/fonts/GT-America/#{$file}-Light.OTF") format("opentype");
  }

  @font-face {
    font-family: $name;
    font-weight: 300;
    font-style: italic;
    src: url("../assets/fonts/GT-America/#{$file}-Light-Italic.OTF") format("opentype");
  }

  @font-face {
    font-family: $name;
    font-weight: 400;
    src: url("../assets/fonts/GT-America/#{$file}-Regular.OTF") format("opentype");
  }

  @font-face {
    font-family: $name;
    font-weight: 400;
    font-style: italic;
    src: url("../assets/fonts/GT-America/#{$file}-Regular-Italic.OTF") format("opentype");
  }

  @font-face {
    font-family: $name;
    font-weight: 500;
    src: url("../assets/fonts/GT-America/#{$file}-Medium.OTF") format("opentype");
  }

  @font-face {
    font-family: $name;
    font-weight: 500;
    font-style: italic;
    src: url("../assets/fonts/GT-America/#{$file}-Medium-Italic.OTF") format("opentype");
  }

  @font-face {
    font-family: $name;
    font-weight: 700;
    src: url("../assets/fonts/GT-America/#{$file}-Bold.OTF") format("opentype");
  }

  @font-face {
    font-family: $name;
    font-weight: 700;
    font-style: italic;
    src: url("../assets/fonts/GT-America/#{$file}-Bold-Italic.OTF") format("opentype");
  }
  
  @font-face {
    font-family: $name;
    font-weight: 900;
    src: url("../assets/fonts/GT-America/#{$file}-Black.OTF") format("opentype");
  }

  @font-face {
    font-family: $name;
    font-weight: 900;
    font-style: italic;
    src: url("../assets/fonts/GT-America/#{$file}-Black-Italic.OTF") format("opentype");
  }
}

@include gt-america(GTAmericaXThin, 'GT-America-Compressed');
@include gt-america(GTAmerica, 'GT-America');
@include gt-america(GTAmericaXWide, 'GT-America-Expanded');