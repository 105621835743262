@import "variables.scss";
@import "reset.scss";
@import "fonts.scss";

$grayBg: #333333;
$grayText: #adadad;
$graySubtext: #7f7f7f;

.details {
  padding: 160px 80px;
  display: flex;

  @media (max-width: 1200px) {
    display: block;
  }

  &__col-left {
    flex: 0 1 auto;
    padding: 60px 40px;
    max-width: 500px;
  }

  &__col-right {
    flex: 1 1 60%;
  }

  &__title {
    font: 900 48px $fontXWide;
    margin: 20px 0;
    text-transform: uppercase;
  }

  &__subtitle {
    font: 700 32px $fontXThin;
    margin-top: 50px;
    text-transform: uppercase;
  }

  &__opensea {
    margin-right: 15px;
  }

  &__attributes {
    display: flex;
  }

  &__attr {
    margin: 0 15px;
  }

  &__attr-label {
    font: 700 16px $fontXThin;
    text-transform: uppercase;
    opacity: 0.75;
  }

  &__attr-value {
    font: 900 16px $fontXWide;
    text-transform: uppercase;
  }

  &__evolution {
    margin-top: 10px;
    color: rgba(255,255,255,0.3);

    span {
      font: 700 20px $fontXThin;
      text-transform: uppercase;
      margin: 0 21px;
      position: relative;
      &:after {
        position: absolute;
        content: url("/assets/images/right_arrow.svg");
        width: 16px;
        height: 16px;
        display: inline-block;
        right: -29px;
        opacity: 0.33;
      }
      &:first-child {
        margin-left: 0;
      }
      &:last-child:after {
        content: "";
      }
    }

    &.selected-common span:nth-child(1),
    &.selected-bronze span:nth-child(2),
    &.selected-silver span:nth-child(3),
    &.selected-gold span:nth-child(4),
    &.selected-black span:nth-child(5) {
      color: #ffffff;
    }
  }

  &__description {
    margin-top: 16px;
  }

  &__progress-bar {
    margin-top: 52px;
  }

  &__evolve-black {
    margin: 32px 0;
    a {
      display: block;
      margin-top: 16px;
      text-transform: uppercase;
      text-decoration: underline;
      font-family: $fontXThin;
    }
  }

  &__donate-button {
    margin-top: 40px;
  }

  &.error {
    display: block;
    text-align: center;
    .details__title {
      font-size: 36px;
      margin-bottom: 36px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .details {
    padding: 160px 36px;
    &__title {
      font-size: 36px;
    }
    &__attributes {
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0 -8px;
    }
    &__opensea {
      flex: 0 0 100%;
      padding: 32px 8px 0;
      order: 5;
    }
    &__attr {
      margin: 0;
      padding: 0 8px 16px;
      flex: 0 0 50%;
    }
    &__progress-bar {
      width: 100%;
    }
    &__evolution {
      span {
        font-size: 16px;
        margin: 0 16px;
        &:after {
          width: 12px;
          height: 12px;
          right: -22px;
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .details {
    padding: 160px 16px;
  }
}